<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-12 p-0 mb-1 congrats-text">
      SORRY!
    </div>
    <div class="col-12 p-0 mb-1 won-text">
      Better luck next time
    </div>
    <div class="col-12 p-0">
      <div class="row justify-content-center">
        <div class="col-6 col-lg-4 p-0 text-center prize mb-3">
          <div class="row mx-0 justify-content-center">
            <div class="col-12">
              <img src="../assets/images/prizes/no_prize.png" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mt-3">
      <div class="col-12 p-0">
        <button @click="goBackToLanding" class="arrow-button">
          <span class="arrow-icon">&gt;</span>
        </button>
      </div>
    </div>
  </div>
  <!-- <div class="row mx-0 bg justify-content-center">
    <div class="col-11 p-0 mb-3 mt-4 text text-center">
      Sorry! Try again next month
    </div>
    <div class="col-12 p-0">
      <div class="row justify-content-center">
        <div class="col-6 col-lg-3 text-center prize mb-3">
          <div class="row mx-0 justify-content-center">
            <div class="col-12">
              <img src="../assets/images/prizes/no_prize.png" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 p-0 my-4 prize text-center">
      CHECK OUT THE #WALLETWISE PAGE FOR MONEY TIPS
    </div>
    <div class="col-auto p-0 my-4 prize text-center">
      <Button @click="goBackToLanding">
        Back to Wallet-Wise
      </Button>
    </div>
  </div> -->
</template>

<script>
export default {
  name: 'Sorry',
  methods: {
    goBackToLanding () {
      this.$emit('goBackToLanding')
    }
  }
}
</script>

<style scoped>
.arrow-button {
  border: none;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  width: 100px;
}

.arrow-button:focus {
  outline: none;
}

.arrow-icon {
  display: inline-block;
  color: #d3202f;
  font-size: 24px; /* Change size as needed */
}
.congrats-text {
  font-size: 2.5rem;
  text-transform: uppercase;
}
.won-text {
  font-size: 4rem;
  text-transform: uppercase;
}
.text {
  color: rgb(252, 181, 51);
  font-size: 1.5rem;
  text-transform: uppercase;
}
.text-sm {
  font-size: 1.25rem;
}
.prize {
  color: rgb(252, 181, 51);
}
@media (min-width: 992px) {
  .text {
    font-size: 2.25rem;
  }
  .text-sm {
    font-size: 1.75rem;
  }
}
</style>
