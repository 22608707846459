<template>
  <div class="background leaderboard-background"></div>
  <img src="../assets/images/logo_avis.png" class="logo" >
  <div v-if="showSection === 'prizes'">
    <div class="row mx-0 position-relative">
      <!-- <div class="col-12 p-0 mb-3 text">
        Prize Generator
      </div> -->
      <div class="col-12 p-0 mb-1 congrats-text">
        Prize Generator
      </div>
      <div class="col-12 p-0 mb-5 won-text">
        Click to see if you've won a prize
      </div>
      <div class="col-12 p-0 mb-3">
        <div class="row justify-content-center justify-content-lg-start">
          <!-- <div class="col-6 col-lg text-center prize mb-3" :class="isActive(0)">
            <div class="row mx-0 justify-content-center">
              <div class="col-12">
                <img src="../assets/images/prizes/power_bank_2.png" class="img-fluid">
              </div>
            </div>
          </div> -->
          <div class="col-3 col-lg text-center prize mb-3" :class="isActive(1)">
            <div class="row mx-0 justify-content-center">
              <div class="col-12">
                <img src="../assets/images/prizes/bushfire/camp_chair.png" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="col-3 col-lg text-center prize mb-3" :class="isActive(2)">
            <div class="row mx-0 justify-content-center">
              <div class="col-12">
                <img src="../assets/images/prizes/bushfire/power_bank.png" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="col-3 col-lg text-center prize mb-3" :class="isActive(2)">
            <div class="row mx-0 justify-content-center">
              <div class="col-12">
                <img src="../assets/images/prizes/bushfire/bottle_opener.png" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="col-3 col-lg text-center prize mb-3" :class="isActive(2)">
            <div class="row mx-0 justify-content-center">
              <div class="col-12">
                <img src="../assets/images/prizes/bushfire/fleece_blanket.png" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="col-3 col-lg text-center prize mb-3" :class="isActive(2)">
            <div class="row mx-0 justify-content-center">
              <div class="col-12">
                <img src="../assets/images/prizes/bushfire/instaselfie_light.png" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="col-3 col-lg text-center prize mb-3" :class="isActive(2)">
            <div class="row mx-0 justify-content-center">
              <div class="col-12">
                <img src="../assets/images/prizes/bushfire/phone_strap.png" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="col-3 col-lg text-center prize mb-3" :class="isActive(2)">
            <div class="row mx-0 justify-content-center">
              <div class="col-12">
                <img src="../assets/images/prizes/bushfire/neck_fan.png" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="col-3 col-lg text-center prize mb-3" :class="isActive(3)">
            <div class="row mx-0 justify-content-center">
              <div class="col-12">
                <img src="../assets/images/prizes/no_prize.png" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mx-0 mt-5">
        <div class="col-12 p-0">
          <button @click="getPrize" class="generate-button">
            GENERATE
          </button>
        </div>
      </div>
    </div>
  </div>
  <Congrats
    v-else-if="showSection === 'congrats'"
    :prize=prize
    @goBackToLanding="goBackToLanding"
  />
  <Sorry
    v-else-if="showSection === 'sorry'"
    @goBackToLanding="goBackToLanding"
  />
</template>

<script>
import { ref } from 'vue'
import Congrats from './Congrats.vue'
import Sorry from './Sorry.vue'
import { getPrize } from '../api'
export default {
  components: { Congrats, Sorry },
  name: 'Prizes',
  setup () {
    const prizePosition = ref(0)
    const isRunning = ref(false)
    const prize = ref('')
    const ticksLeft = ref(21)
    const showSection = ref('prizes')
    const loader = ref(null)
    return {
      prizePosition,
      isRunning,
      prize,
      ticksLeft,
      showSection,
      loader
    }
  },
  async mounted () {
    const response = await getPrize(this.$route.params.id)
    this.prize = response.data.data
  },
  methods: {
    getPrize () {
      if (!this.isRunning) {
        this.loader = this.$loading.show()
        this.togglePrizeGenerator()
      }
    },
    togglePrizeGenerator () {
      if (this.isRunning) {
        clearInterval(this.interval)
        // console.log('timer stops')
      } else {
        this.interval = setInterval(this.movePrize, 100)
      }
      this.isRunning = !this.isRunning
    },
    movePrize () {
      if (this.prizePosition === 4) {
        this.prizePosition = 0
      } else {
        this.prizePosition += 1
      }
      if (this.prize !== '' && this.ticksLeft <= 0) {
        this.togglePrizeGenerator()
        // console.log('this.prize', this.prize)
        this.loader.hide()
        switch (this.prize) {
          case 'no_prize':
            this.showSection = 'sorry'
            break
          default:
            this.showSection = 'congrats'
            break
        }
        // switch (this.prize) {
        //   case 'laptop':
        //     this.prizePosition = 0
        //     break
        //   case 'tablet':
        //     this.prizePosition = 1
        //     break
        //   case 'data':
        //     this.prizePosition = 2
        //     break
        //   case 'checkers':
        //     this.prizePosition = 3
        //     break
        //   default:
        //     this.prizePosition = 4
        //     break
        // }
      }
      this.ticksLeft--
    },
    isActive (position) {
      if ((this.isRunning || this.prize) && position === this.prizePosition) return 'active'
      return ''
    },
    goBackToLanding () {
      // window.location.href = 'https://www.standardbank.co.za/southafrica/personal/campaigns/walletwise-for-youth'
      this.$router.push(`/`)
    }
  }
}
</script>

<style scoped>
.text {
  color: #fff;
  font-size: 2rem;
  text-transform: uppercase;
}
.prize {
  color: #fff;
  /* opacity: 0.4; */
}
.active {
  /* opacity: 1; */
  border-color: blue;
}
.img-fluid {
  max-height: 30vh;
}
.absolute_bottom {
  position: fixed;
  bottom: 0.5rem;
  text-align: center;
}
.congrats-text {
  font-size: 2.2rem;
  text-transform: uppercase;
}
.won-text {
  font-size: 2.5rem;
  text-transform: uppercase;
}
.generate-button {
  border: none;
  color: #d3202f;
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  width: 200px;
}
@media (min-width: 992px) {
  .absolute_bottom {
    bottom: 2rem;
  }
  .text {
    font-size: 2.5rem;
  }
  .col-6 {
    flex: 1 0 0%;
  }
}
</style>
